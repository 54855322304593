import React from "react";
import { ReactComponent as SecuredByXPayLogoRC } from "../../assets/securedByXpayLogo.svg";
const Header = ({ setLoadingMain, mode, merchantLogo }) => {
  const handleImageLoad = () => {
    setLoadingMain(false);
  };

  return (
    <div className="relative pb-7">
      <div className="flex items-center justify-between">
        {merchantLogo ? (
          <>
            <img
              src={merchantLogo}
              alt="xPay logo"
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              className="rounded-lg max-h-14 max-w-28 drop-shadow-sm"
            />

            {mode === "TEST" && (
              <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs absolute left-[calc((100vw-130px)/2)] sm:left-[168px]">
                Test Mode
              </div>
            )}
            <div>
              <SecuredByXPayLogoRC className="w-16 h-9" />
            </div>
          </>
        ) : (
          <>
            <SecuredByXPayLogoRC className="w-20 h-10" />
            {mode === "TEST" && (
              <div className="text-yellow-600 bg-[#FFF9DE] rounded-full py-[6px] px-3 text-xs">
                Test Mode
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
