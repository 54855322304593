import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { ErrorHandler } from "../../utils/ErrorHandler";
import axios from "../../axios";

const usePaymentConfirmation = () => {
  const { setStatus, intentDetails } = useOneTimePaymentsContext();

  const collectStripeCallbackData = async () => {
    try {
      await axios.post(`/payments/callback/${intentDetails.xIntentId}`, {});
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  const triggerRedirect = async () => {
    try {
      if (!intentDetails?.callbackUrl) {
        ErrorHandler(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(intentDetails.callbackUrl);
      callback.searchParams.set("xpay_intent_id", intentDetails.xIntentId);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  return {
    triggerRedirect,
    collectStripeCallbackData,
  };
};

export default usePaymentConfirmation;
