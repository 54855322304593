/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";

const OneTimePaymentsContext = createContext({});

export const OneTimePaymentsProvider = (props) => {
  const [form, setForm] = useState(); // Deprecate it from charge card
  const [amount, setAmount] = useState();
  const [merchantName, setMerchantName] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [loadingMain, setLoadingMain] = useState(true);
  const [loading, setLoading] = useState(true);
  const [paymentError, setPaymentError] = useState(false);
  const [status, setStatus] = useState(null);
  const [mode, setMode] = useState();
  const urlParam = new URLSearchParams(window.location.search);
  const [xIntentId, setXIntentId] = useState(urlParam.get("xpay_intent_id"));
  const [currency, setCurrency] = useState(urlParam.get("currency") || "USD");
  const [globalIntentData, setGlobalIntentData] = useState();
  const [CSCProps, setCSCProps] = useState();
  const [intentDetails, setIntentDetails] = useState();
  const [loadingMethods, setLoadingMethods] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [isPaymentBtnDisabled, setIsPaymentBtnDisabled] = useState(true);
  const [callbackUrl, setCallbackUrl] = useState();
  const [stripeElement, setStripeElement] = useState();
  const [stripePromise, setStripePromise] = useState();
  const [billingDetails, setBillingDetails] = useState();
  const [paymentRequest, setPaymentRequest] = useState();
  const [country, setCountry] = useState();
  const [addressElement, setAddressElement] = useState();

  return (
    <OneTimePaymentsContext.Provider
      value={{
        form,
        setForm,
        currency,
        setCurrency,
        amount,
        setAmount,
        globalIntentData,
        setGlobalIntentData,
        xIntentId,
        setXIntentId,
        paymentMethodList,
        setPaymentMethodList,
        merchantName,
        setMerchantName,
        intentDetails,
        setIntentDetails,
        loading,
        setLoading,
        loadingMain,
        setLoadingMain,
        paymentError,
        setPaymentError,
        status,
        setStatus,
        mode,
        setMode,
        CSCProps,
        setCSCProps,
        loadingMethods,
        setLoadingMethods,
        paymentMethod,
        setPaymentMethod,
        isPaymentBtnDisabled,
        setIsPaymentBtnDisabled,
        callbackUrl,
        setCallbackUrl,
        stripeElement,
        setStripeElement,
        stripePromise,
        setStripePromise,
        billingDetails,
        setBillingDetails,
        paymentRequest,
        setPaymentRequest,
        country,
        setCountry,
        addressElement,
        setAddressElement,
      }}
    >
      {props.children}
    </OneTimePaymentsContext.Provider>
  );
};

export const useOneTimePaymentsContext = () => {
  return useContext(OneTimePaymentsContext);
};
