import React from "react";
import { ReactComponent as SuccessIcon } from "../../../assets/Icons/toast/check_circle.svg";

const SuccessToast = ({ t, message }) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } flex items-start justify-between p-4 rounded-lg shadow-md bg-[#EFFFF6] border border-[#E0FFEF] sm:w-[416px] w-[364px] text-xs sm:text-[16px] leading-6`}
    >
      <div className="flex items-start gap-4">
        <div className="w-5 h-5">
          <SuccessIcon />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default SuccessToast;
