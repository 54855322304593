/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import posthog from "posthog-js";

const StripeCheckoutForm = () => {
  const {
    setIsPaymentBtnDisabled,
    loading,
    setStripeElement,
    billingDetails,
    paymentMethod,
    setAddressElement,
  } = useOneTimePaymentsContext();
  const elements = useElements();

  useEffect(() => {
    if (!elements) return;
    setStripeElement(elements);

    const paymentElement = elements.getElement(PaymentElement);
    setAddressElement(elements.getElement(AddressElement));

    if (paymentElement) {
      paymentElement.on("ready", (event) => {
        posthog.capture("stripe_ready", { stripe_event: event });
      });

      paymentElement.on("change", (event) => {
        posthog.capture("stripe_change", { stripe_event: event });
      });

      paymentElement.on("focus", (event) => {
        posthog.capture("stripe_focus", { stripe_event: event });
      });

      paymentElement.on("blur", (event) => {
        posthog.capture("stripe_blur", { stripe_event: event });
      });

      paymentElement.on("escape", (event) => {
        posthog.capture("stripe_escape", { stripe_event: event });
      });

      paymentElement.on("click", (event) => {
        posthog.capture("stripe_click", { stripe_event: event });
      });

      paymentElement.on("loaderror", (event) => {
        posthog.capture("stripe_loaderror", { stripe_event: event });
      });

      paymentElement.on("loaderstart", (event) => {
        posthog.capture("stripe_loaderstart", { stripe_event: event });
      });
    }
  }, [elements]);

  return (
    <>
      {!loading && (
        <div className="pb-4">
          {paymentMethod === "AFTERPAY" && (
            <AddressElement
              options={{
                mode: "shipping",
                defaultValues: {
                  name: billingDetails?.name,
                  address: {
                    postal_code: billingDetails?.pincode,
                    country: billingDetails?.country,
                  },
                },
              }}
            />
          )}
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  name: billingDetails?.name,
                  email: billingDetails?.email,
                  address: {
                    postal_code: billingDetails?.pincode,
                  },
                },
              },
              wallets: {
                applePay: "never",
                googlePay: "never",
              },
              fields: {
                billingDetails: {
                  name: paymentMethod === "AFTERPAY" ? "never" : "auto",
                },
              },
            }}
            onChange={(event) => {
              setIsPaymentBtnDisabled(!event.complete);
            }}
          />
        </div>
      )}
    </>
  );
};

export default StripeCheckoutForm;
