import React from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import ChargeCard from "./../ChargeCard";
import StripeGateway from "../StripeGateway/StripeGateway";
import PaymentError from "../../UI/PaymentError";
import TestCardsContainer from "../../TestMode/TestCardsContainer";
import { ReactComponent as InfoIcon } from "../../../assets/Icons/toast/info.svg";

const Payment = () => {
  const { intentDetails, paymentError, mode, paymentMethod } =
    useOneTimePaymentsContext();

  const renderPg = () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        return <ChargeCard />;
      case "STRIPE":
        return <StripeGateway />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-473px)] sm:px-2 flex flex-col gap-4 relative`}
      id="payment"
    >
      {paymentError && <PaymentError paymentError={paymentError} />}
      {paymentMethod === "KLARNA" && (
        <div className="text-sm font-medium flex gap-2 p-2 bg-[#EEF8FF] border border-[#DFF2FF] rounded-lg">
          <div className="w-5 h-5">
            <InfoIcon />
          </div>{" "}
          This payment method will work for Klarna US account holders only.
        </div>
      )}
      {paymentMethod === "AFTERPAY" && (
        <div className="text-sm font-medium flex gap-2 p-2 bg-[#EEF8FF] border border-[#DFF2FF] rounded-lg">
          <div className="w-5 h-5">
            <InfoIcon />
          </div>{" "}
          This payment method will work for Afterpay US account holders only.
        </div>
      )}
      {renderPg()}
      {mode === "TEST" && paymentMethod === "CARD" && (
        <TestCardsContainer pg={intentDetails.pg} />
      )}
    </div>
  );
};

export default Payment;
