import React from "react";
import { ReactComponent as WarningIcon } from "../../../assets/Icons/toast/warning.svg";

const WarningToast = ({ t, message }) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } flex items-start justify-between p-4 rounded-lg shadow-md bg-[#FFF7ED] border border-[#FFF1DF] sm:w-[416px] w-[364px] text-xs sm:text-[16px] leading-6`}
    >
      <div className="flex items-start gap-4">
        <div className="w-5 h-5">
          <WarningIcon />
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default WarningToast;
