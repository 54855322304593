import axios from "../../axios";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useTriggerIntent = () => {
  const {
    xIntentId,
    setAmount,
    setCurrency,
    setIntentDetails,
    setMerchantName,
    setStatus,
    setLoadingMethods,
  } = useOneTimePaymentsContext();

  const triggerIntent = async ({ value, currency }) => {
    let pm = value;
    if (value === "GOOGLE_PAY") {
      pm = "CARD";
    } else if (value === "APPLE_PAY") {
      pm = "CARD";
    }
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(`/payments/v2/trigger-intent`, {
        xIntentId: xIntentId,
        currency: currency,
        paymentMethod: pm,
      });

      setIntentDetails(data);
      setAmount(data.presentmentAmount);
      setCurrency(data.presentmentCurrency);
      setMerchantName(data.merchantName);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoadingMethods(false);
    }
  };

  return { triggerIntent };
};

export default useTriggerIntent;
