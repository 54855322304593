import PaymentStatusSuccess from "../StatusScreens/PaymentStatusSuccess";
import PaymentStatusError from "../StatusScreens/PaymentStatusError";
import usePaymentConfirmation from "../../hooks/OneTimePayment/usePaymentConfirmation";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useEffect } from 'react';

const PaymentStatusView = ({ status, id }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { triggerRedirect } = usePaymentConfirmation();
  const { loadingMain, xIntentId } = useOneTimePaymentsContext();

  switch (status) {
    case "SUCCESS":
      return (
        <PaymentStatusSuccess
          triggerRedirect={triggerRedirect}
          loadingMain={loadingMain}
        />
      );
    case "ERROR":
    default:
      return <PaymentStatusError id={xIntentId || id} />;
  }
};

export default PaymentStatusView;
