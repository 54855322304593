/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { paymentMethod } from "../../constants/paymentMethod";
import { ErrorHandler } from "../../utils/ErrorHandler";
import useTriggerIntent from "./useTriggerIntent";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import config from "../../../config";

const usePaymentMethod = () => {
  const {
    xIntentId,
    setStatus,
    loadingMethods,
    setLoadingMethods,
    setPaymentMethod,
    paymentRequest,
    setPaymentRequest,
    amount,
    currency,
  } = useOneTimePaymentsContext();

  const { triggerIntent } = useTriggerIntent();

  const [paymentMethodList, setPaymentMethodList] = useState();

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        currency: currency.toLowerCase(),
        total: {
          label: "Total",
          amount: amount,
        },
      });
    }
  }, [amount, currency, paymentMethodList]);

  const getPaymentMethod = async (value, prefetchData) => {
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(`/payments/v2/prefetchPaymentMethods`, {
        intentId: xIntentId,
        currency: value,
      });

      const paymentMethodOptions = [];

      data.paymentMethods.forEach((item) => {
        paymentMethodOptions.push(
          paymentMethod.find((method) => method.id === item)
        );
      });

      const promise = await loadStripe(config.stripe_pg_public_key);

      const paymentRequest = promise.paymentRequest({
        country: "US",
        currency:
          currency.toLowerCase() ||
          prefetchData?.primary?.currency.toLowerCase(),
        total: {
          label: "Total",
          amount: amount || prefetchData?.primary?.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      setPaymentRequest(paymentRequest);

      await paymentRequest.canMakePayment().then((result) => {
        if (prefetchData?.country !== "IN") {
          if (result && result.applePay) {
            paymentMethodOptions.push(
              paymentMethod.find((method) => method.id === "APPLE_PAY")
            );
          }
          if (result && result.googlePay) {
            paymentMethodOptions.push(
              paymentMethod.find((method) => method.id === "GOOGLE_PAY")
            );
          }
        }
      });

      if (paymentMethodOptions.length === 1) {
        setPaymentMethod(paymentMethodOptions[0].value);
        await triggerIntent({
          value: paymentMethodOptions[0].value,
          currency: value,
        });
      }

      setPaymentMethodList(paymentMethodOptions);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoadingMethods(false);
    }
  };

  return {
    getPaymentMethod,
    paymentMethodList,
    loadingMethods,
    setLoadingMethods,
  };
};

export default usePaymentMethod;
