import React from "react";

// Icons
import { MdOutlineMode } from "react-icons/md";
import { ReactComponent as UserTick } from "../../assets/Icons/Check-filled.svg";

const UserBox = ({ setOpen, billingDetails }) => {
  const showDrawer = () => {
    setOpen(true);
  };

  const maxCharacters =
    window.innerWidth > 512 ? 40 : (window.innerWidth - 176) / 8;

  return (
    <div className="flex items-center justify-between gap-2 px-4 py-2 border border-gray-200 rounded-lg sm:p-4">
      <div>
        <div className="flex items-center gap-2 font-medium">
          <span>
            {billingDetails?.name?.length > maxCharacters
              ? billingDetails?.name?.slice(0, maxCharacters - 5) + "..."
              : billingDetails?.name}
          </span>{" "}
          <UserTick />
        </div>
        <div className="text-[#535353] font-normal">
          {billingDetails?.email?.length +
            billingDetails?.country?.length +
            billingDetails?.pincode?.length >
          maxCharacters ? (
            <div>
              {billingDetails?.email?.length > maxCharacters
                ? billingDetails?.email?.slice(0, maxCharacters) + "..."
                : billingDetails?.email}
              <br />
              {billingDetails?.pincode?.length + billingDetails?.country?.length >
              maxCharacters
                ? billingDetails?.pincode
                  ? `${billingDetails?.pincode}, ${billingDetails?.country
                      ?.split("-")[1]
                      ?.slice(
                        0,
                        maxCharacters - billingDetails?.pincode?.length - 1
                      )}...`
                  : `${billingDetails?.country
                      .split("-")[1]
                      .slice(0, maxCharacters)}...`
                : billingDetails?.pincode
                ? billingDetails?.pincode +
                  ", " +
                  billingDetails?.country?.split("-")[1]
                : billingDetails?.country?.split("-")[1]}
            </div>
          ) : (
            `${billingDetails?.email} • ${
              billingDetails?.pincode ? `${billingDetails?.pincode}, ` : ""
            } ${billingDetails?.country?.split("-")[1]}`
          )}
        </div>
      </div>
      <div
        className="rounded-full bg-[#F1F1F1] w-[25px] h-[25px] sm:w-[40px] sm:h-[40px] flex items-center justify-center cursor-pointer"
        onClick={showDrawer}
      >
        <MdOutlineMode className="sm:text-2xl " />
      </div>
    </div>
  );
};

export default UserBox;
