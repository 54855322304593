import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";

const useTriggerPayment = () => {
  const {
    pg,
    globalIntentData,
    subscriptionId,
    stripePromise,
    stripeElement,
    setPaymentError,
    setStatus,
    callbackUrl,
    currency,
    pgPublicKey,
    setErrorMessage,
    amount,
    form,
    paymentMethod,
  } = useSubscriptionContext();

  const [payBtnLoading, setPayBtnLoading] = useState(false);

  const triggerPayment = async () => {
    switch (pg) {
      case "AUTHNET":
        await chargeCard();
        break;
      case "STRIPE":
        await stripePay();
        break;
      default:
        break;
    }
  };

  const chargeCard = async () => {
    await form
      .validateFields()
      .then(async (values) => {
        const cardValidity = globalIntentData?.cardValidity?.split(" / ");
        const cardNumber = globalIntentData?.cardNumber?.replace(/\s/g, "");
        const cardDetails = {
          cardNumber: cardNumber,
          cardHolderName: globalIntentData?.cardName,
          expiryMonth: cardValidity[0],
          expiryYear: cardValidity[1],
          cardCode: globalIntentData?.cardSecurityCode,
        };

        setPayBtnLoading(true);

        try {
          const { data } = await axios.post(`/payments/charge-card`, {
            subscriptionId: subscriptionId,
            cardDetails: cardDetails,
          });

          if (data?.status === "SUCCESS") {
            setStatus("SUCCESS");
          } else {
            setPaymentError(data?.errorMessage);
          }
        } catch (error) {
          ErrorHandler(error, setStatus);
        } finally {
          setPayBtnLoading(false);
        }
      })
      .catch((error) => {
        setPaymentError("Please fill in all required fields.");
      });
  };

  const stripePay = async () => {
    const elements = stripeElement;
    if (!stripePromise || !elements) {
      return;
    }

    setPayBtnLoading(true);

    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set("callbackUrl", callbackUrl);
    returnUrl.searchParams.set("paymentMethod", paymentMethod);
    returnUrl.searchParams.set("currency", currency);
    returnUrl.searchParams.set("pgPublicKey", pgPublicKey);
    returnUrl.searchParams.set("amount", amount);

    const { error, setupIntent } = await stripePromise.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl.href,
      },
      redirect: "if_required",
    });

    if (error) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      const element = document.getElementById("payment");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
          marginTop: "-100px",
        });
      }
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    } else if (setupIntent) {
      if (setupIntent.status === "succeeded") {
        try {
          const { data } = await axios.post(
            `/subscription/trigger-ack/${subscriptionId}`,
            {}
          );
          setStatus(data.status);
          data.errorMessage && setErrorMessage(data.errorMessage);
        } catch (error) {
          ErrorHandler(error, setStatus);
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        const element = document.getElementById("payment");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
            marginTop: "-100px",
          });
        }
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    }

    setPayBtnLoading(false);
  };

  return { triggerPayment, payBtnLoading };
};

export default useTriggerPayment;
