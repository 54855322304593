import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";
import axios from "../../axios";

const usePaymentConfirmation = () => {
  const { setStatus, subscriptionId, callbackUrl } = useSubscriptionContext();

  const collectStripeCallbackData = async () => {
    try {
      await axios.post(`/subscription/trigger-ack/${subscriptionId}`, {});
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  const triggerRedirect = async () => {
    try {
      if (!callbackUrl) {
        ErrorHandler(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(callbackUrl);
      callback.searchParams.set("subscription_id", subscriptionId);
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  return {
    triggerRedirect,
    collectStripeCallbackData,
  };
};

export default usePaymentConfirmation;
