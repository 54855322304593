import React from "react";
import { Button } from "antd";
import useTriggerPayment from "../../hooks/Subscription/useTriggerPayment";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";

const ProceedButton = () => {
  const { triggerPayment, payBtnLoading } = useTriggerPayment();
  const { isPaymentBtnDisabled } = useSubscriptionContext();

  const handlePay = async () => {
    const element = document.getElementById("paymentBlock");
    if (element) {
      const top = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top, behavior: "smooth" });
    }

    await triggerPayment();
  };

  return (
    <div className="mt-4 sm:mt-6">
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        onClick={handlePay}
        loading={payBtnLoading}
        disabled={isPaymentBtnDisabled}
      >
        Subscribe
      </Button>
    </div>
  );
};

export default ProceedButton;
