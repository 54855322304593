import { useEffect } from "react";
import posthog from "posthog-js";
import toast from "react-hot-toast";
import SuccessToast from "../components/UI/Toasts/SuccessToast";
import WarningToast from "../components/UI/Toasts/WarningToast";

const ConnectivityTracker = () => {
  useEffect(() => {
    const handleOnline = () => {
      toast.custom(
        (t) => <SuccessToast t={t} message={"You are back online."} />,
        {
          id: "ConnectionToast",
          duration: 3000,
        }
      );
      posthog.capture("User Online", {
        status: "online",
        timestamp: new Date().toISOString(),
        event_type: "online",
      });
    };

    const handleOffline = () => {
      toast.custom((t) => <WarningToast t={t} message={"You are offline."} />, {
        id: "ConnectionToast",
        duration: Infinity,
      });
      posthog.capture("User Offline", {
        status: "offline",
        timestamp: new Date().toISOString(),
        event_type: "offline",
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return null;
};

export default ConnectivityTracker;
